<template>
  <div class="c--homepage_data-type-tabs mb-30 display-flex justify-content-center">
    <div
      class="display-inline-flex align-items-start justify-content-center box-rounded overflow-hidden tw-w-full sm:tw-w-auto overflow-hidden data-type-tabs"
    >
      <button
        v-for="(item, index) in dataTypeItems"
        :key="`plain-type-item-${index}`"
        :class="[
          buttonClasses,
          item.classes,
          {
            selected: item.value === selectedDataType,
          },
        ]"
        :data-testid="`${item.value}-tab`"
        @click.prevent="changeSelectedDataType(item.value)"
      >
        <span class="tw-relative tw-z-10">{{ $t(item.title) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      selectedDataType: {
        type: String, //others, data-voice-text
        required: false,
        default: 'others',
      },
      id: {
        type: String,
        required: false,
        default: 'default',
      },
      type: {
        type: String,
        required: false,
        default: 'vertical', // vertical, horizontal
      },
    },

    data() {
      return {
        dataTypeItems: [
          { value: 'others', title: 'components.plain.type.data', classes: '' },
          {
            value: 'data-voice-text',
            title: 'components.plain.type.data-calls-texts',
            classes: '',
          },
        ],
        buttonClasses:
          'data-type-tabs-button tw-min-w-[50%] sm:tw-min-w-[175px] typo-label typo-sm-p-tabs tw-py-[7.5px] sm:tw-py-[15px] px-20 border-none tw-relative',
      }
    },

    methods: {
      changeSelectedDataType(type) {
        this.$emit('onChangePlainType', type)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .data-type-tabs {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);

    @media screen and (min-width: 576px) {
      border: 1px solid #b8b8b8;
      box-shadow: none;
    }
  }
  .data-type-tabs-button {
    color: #4a4a4c;
    background: #ffffff;
    transition: color 0.3s ease-out;

    @media screen and (min-width: 576px) {
      color: #8a8a8a;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      background: linear-gradient(270deg, #aa5481 0%, #e2444f 100%);
      transition: opacity 0.3s ease-out;
      z-index: 9;
    }

    &.selected {
      color: #ffffff;

      &::after {
        opacity: 1;
      }
    }

    & + .data-type-tabs-button {
      @media screen and (min-width: 576px) {
        border-left: 1px solid #b8b8b8;

        [dir='rtl'] & {
          border-left: none;
          border-right: 1px solid #b8b8b8;
        }
      }
    }
  }
</style>
