<template>
  <div class="tw-grid">
    <div class="tw-max-w-6xl tw-m-auto tw-w-full tw-relative tw-px-4">
      <div class="tw-max-w-md sm:tw-max-w-lg">
        <h1 data-testid="homepage-title" class="tw-relative tw-text-left rtl:tw-text-right tw-text-white">
          {{ title }}<span v-if="seoTitle" class="sr-only"> | {{ seoTitle }}</span>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      seoTitle: {
        type: String,
        required: false,
        default: '',
      },
    },
  }
</script>
